import React from "react";
import { useContext } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import FullWidthImage from "../components/FullWidthImage";

import {
  Anchor,
  Box,
  Heading,
  Image,
  Meter,
  ResponsiveContext,
  Stack,
  Text,
} from "grommet";
import chantriesLogo from "../img/chantries-logo.png";
import shalfordMural from "../img/shalford-mural.jpg";
import shalfordChildren from "../img/shalford-children.jpg";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {
  const size = useContext(ResponsiveContext);
  const heroImage = getImage(image) || image;
  const value = 65;
  return (
    <div>
      <FullWidthImage img={heroImage} title={heading} />
      <Box
        justify="center"
        align="center"
        fill="horizontal"
        direction="column"
        pad={
          size === "small" ? { horizontal: "20px" } : { horizontal: "100px" }
        }
      >
        <Box margin={{ vertical: "large" }} align="center" fill="horizontal">
          <Heading level="1">2023/2024 Fundraising</Heading>
          <Box
            width={size === "small" ? "100%" : "80%"}
            direction={size === "small" ? "column" : "row"}
            margin={{ vertical: "large" }}
            align="center"
          >
            <Box width={size === "small" ? "100%" : "50%"}>
              <Text>
                We've set a fundraising target of £8,000 for the year.
              </Text>
              <Text>This will go towards the following:</Text>
              <Text>
                <ul>
                  <li>
                    A set of Bee-Bots - programmable robots that all classes can
                    enjoy ✅
                  </li>
                  <li>A budget for each of the four classes</li>
                  <li>
                    Facilitating big projects and improvements to the children's
                    learning environments
                  </li>
                  <li>Essential school supplies</li>
                </ul>
              </Text>
              <Text>
                Check this page for updates and see how you've helped us reach
                our fundraising goals!
              </Text>
            </Box>
            <Box
              width={size === "small" ? "100%" : "50%"}
              justify="center"
              margin={size === "small" ? { top: "xlarge" } : { top: "none" }}
            >
              <Stack anchor="center" alignSelf="center">
                <Meter
                  background="light-3"
                  color="red"
                  type="circle"
                  value={value}
                  thickness="large"
                />
                <Box direction="column" align="center" gap="small">
                  <Text size="60px" weight="bold" style={{ lineHeight: 1 }}>
                    £8,000
                  </Text>
                </Box>
              </Stack>
              <Box
                justify="center"
                margin={{ top: "small" }}
                alignSelf="center"
              >
                <Text size="10px" color="#666">
                  Updated May 2024
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        background="light-2"
        pad={{ vertical: "medium", horizontal: "100px" }}
        justify="center"
        align="center"
        gap="medium"
      >
        <Box align="center">
          <Text size="small" weight="bold">
            Proudly supported by
          </Text>
        </Box>

        <Box gap="medium" margin={{ vertical: "small" }} align="center">
          <Anchor href="https://chantriesandpewleys.com/" target="_blank">
            <Image width="200px" src={chantriesLogo} />
          </Anchor>
        </Box>
        <Box align="center" gap="small">
          <Link to="/sponsors">
            <Text weight="bold" color="text" textAlign="center">
              Offers for Parents
            </Text>
          </Link>
          <Text size="small" weight="bold" color="#999" textAlign="center">
            Interested in being a sponsor?{" "}
            <Anchor
              href="mailto:shalfordpta@gmail.com"
              style={{ color: "brand" }}
            >
              Contact us
            </Anchor>
          </Text>
        </Box>
      </Box>
      <Box
        justify="center"
        align="center"
        fill="horizontal"
        direction="column"
        pad={
          size === "small" ? { horizontal: "20px" } : { horizontal: "100px" }
        }
      >
        <Box margin={{ vertical: "large" }} align="center">
          <Heading level="1">{mainpitch.title}</Heading>
          <Box
            width={size === "small" ? "100%" : "80%"}
            margin={{ vertical: "large" }}
          >
            <Text textAlign="center">{mainpitch.description}</Text>
          </Box>
        </Box>
      </Box>

      <Image
        src={shalfordChildren}
        height="350px"
        fill="horizontal"
        fit="cover"
      />

      <Box
        justify="center"
        align="center"
        fill="horizontal"
        direction="column"
        pad={
          size === "small" ? { horizontal: "20px" } : { horizontal: "100px" }
        }
      >
        <Box margin={{ vertical: "large" }} align="center">
          <Heading level="1">Contact Us</Heading>
          <Box
            width={size === "small" ? "100%" : "80%"}
            margin={{ vertical: "large" }}
          >
            <Text textAlign="center">
              If you're interested in sponsorship of the PTA or if you'd like to
              get involved, please contact us:{" "}
              <Anchor
                href="mailto:shalfordpta@gmail.com"
                style={{ color: "brand" }}
              >
                shalfordpta@gmail.com
              </Anchor>
              .
            </Text>
          </Box>
        </Box>
      </Box>

      {/* <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h1 className="title">{mainpitch.title}</h1>
                    </div>
                    <div className="tile">
                      <h3 className="subtitle">{mainpitch.description}</h3>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {heading}
                      </h3>
                      <p>{description}</p>
                    </div>
                  </div>
                  <Features gridItems={intro.blurbs} />
                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/products">
                        See all products
                      </Link>
                    </div>
                  </div>
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      Latest stories
                    </h3>
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
